









































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  mounted() {
    window.onpopstate = () => {
      this.$router.go(-1);
    };
  }

  navigateToHome() {
    if (!this.$store.state.auth.username) {
      this.$store.commit('logout');
    }
    this.resetLocalStore();
    this.$router.push({ path: '/' });
  }

  logout() {
    this.$store.commit('logout');
    this.$router.push('/login');
  }

  resetLocalStore() {
    this.$store.commit('resetCourse');
    this.$store.commit('resetAllFilters');
    this.$store.commit('clearNavQuestionList');
    this.$store.commit('resetScope');
    this.$store.commit('selectApp', {
      appId: '',
      activeChildRight: '',
      subSystemId: '',
    });
    this.$store.commit('openApps');
  }
}
